/* @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;700&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap");

@font-face {
  font-family: "GT Super";
  src: url("../lib/fonts/GT-Super/GT-Super-Display-Super.otf"); /* IE9 Compat Modes */
  src: url("../lib/fonts/GT-Super/GT-Super-Display-Super.eot")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../lib/fonts/GT-Super/GT-Super-Display-Super.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../lib/fonts/GT-Super/GT-Super-Display-Super.woff") format("woff"),
    /* Pretty Modern Browsers */
      url("../lib/fonts/GT-Super/GT-Super-Display-Super.ttf") format("truetype"); /* Safari, Android, iOS */
}

/**
 * Black theme for reveal.js. This is the opposite of the 'white' theme.
 *
 * By Hakim El Hattab, http://hakim.se
 */
/* @import url(../theme/fonts/source-sans-pro/source-sans-pro.css); */
section.has-light-background,
section.has-light-background h1,
section.has-light-background h2,
section.has-light-background h3,
section.has-light-background h4,
section.has-light-background h5,
section.has-light-background h6 {
  color: #222;
}

/*********************************************
 * GLOBAL STYLES
 *********************************************/

:root {
  --block-margin: 20px;
  --heading-margin: 0 0 20px 0;
  --heading-line-height: 1.2;
  --heading-letter-spacing: normal;
  --heading-text-transform: initial;
  --heading-text-shadow: none;
  --heading-font-weight: 600;
  --heading1-text-shadow: none;
  --heading1-size: 2.5em;
  --heading2-size: 1.6em;
  --heading3-size: 1.3em;
  --heading4-size: 1em;
  --code-font: monospace;
  --selection-color: #fff;
}

.reveal .slides section,
.reveal .slides section > section {
  line-height: 1.3;
  font-weight: inherit;
}

/*********************************************
 * HEADERS
 *********************************************/
.reveal h1,
.reveal h2,
.reveal h3,
.reveal h4,
.reveal h5,
.reveal h6 {
  margin: 6px 0 12px 0;
  font-weight: 500;
  line-height: 0.925;
  letter-spacing: normal;
  text-transform: initial;
  text-shadow: none;
  word-wrap: break-word;
}

/*********************************************
 * OTHER
 *********************************************/
.reveal p {
  margin: 20px 0;
  line-height: 1.3;
}

/* Ensure certain elements are never larger than the slide itself */
.reveal img,
.reveal video,
.reveal iframe {
  max-width: 95%;
  max-height: 95%;
}

.reveal strong,
.reveal b {
  font-weight: bold;
  font-size: 112%;
  margin: 0 0 15px;
  display: inline-block;
}

.reveal em {
  font-style: italic;
}

.reveal ol,
.reveal dl,
.reveal ul {
  display: inline-block;
  text-align: left;
  margin: 0 0 0 1em;
}

.reveal ol {
  list-style-type: decimal;
}

.reveal ul {
  list-style-type: disc;
}

.reveal ul ul {
  list-style-type: square;
}

.reveal ul ul ul {
  list-style-type: circle;
}

.reveal ul ul,
.reveal ul ol,
.reveal ol ol,
.reveal ol ul {
  display: block;
  margin-left: 40px;
}

.reveal dt {
  font-weight: bold;
}

.reveal dd {
  margin-left: 40px;
}

.reveal blockquote p:first-child,
.reveal blockquote p:last-child {
  display: inline-block;
}

.reveal q {
  font-style: italic;
}

.reveal pre {
  display: block;
  position: relative;
  width: 90%;
  margin: 20px auto;
  text-align: left;
  font-size: 0.55em;
  font-family: monospace;
  line-height: 1.2em;
  word-wrap: break-word;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
}

.reveal code {
  font-family: monospace;
  text-transform: none;
}

.reveal pre code {
  display: block;
  padding: 5px;
  overflow: auto;
  max-height: 400px;
  word-wrap: normal;
}

.reveal table {
  margin: auto;
  border-collapse: collapse;
  border-spacing: 0;
}

.reveal table th {
  font-weight: bold;
}

.reveal table th,
.reveal table td {
  text-align: left;
  padding: 0.2em 0.5em 0.2em 0.5em;
  border-bottom: 1px solid;
}

.reveal table th[align="center"],
.reveal table td[align="center"] {
  text-align: center;
}

.reveal table th[align="right"],
.reveal table td[align="right"] {
  text-align: right;
}

.reveal table tbody tr:last-child th,
.reveal table tbody tr:last-child td {
  border-bottom: none;
}

.reveal sup {
  vertical-align: super;
  font-size: smaller;
}

.reveal sub {
  vertical-align: sub;
  font-size: smaller;
}

.reveal small {
  display: inline-block;
  font-size: 0.6em;
  line-height: 1.2em;
  vertical-align: top;
}

.reveal small * {
  vertical-align: top;
}

.reveal img {
  margin: 20px 0;
}

/*********************************************
 * LINKS
 *********************************************/

.reveal .roll span:after {
  color: #fff;
  background: #00658c;
}

/*********************************************
 * Frame helper
 *********************************************/
.reveal .r-frame {
  border: 4px solid #3d4b52;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.reveal a .r-frame {
  transition: all 0.15s linear;
}

.reveal a:hover .r-frame {
  border-color: #009cd8;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.55);
}

/*********************************************
 * PRINT BACKGROUND
 *********************************************/
@media print {
  .backgrounds {
    background-color: #edecf7;
  }
}

/*********************************************
 * OVERRIDES
 *********************************************/
html {
  font-size: 1em;
  user-zoom: fixed;
}

.reveal .slides {
  /* max-width: 82%; */
  top: -50px !important;
}

@media (max-width: 767px) {
  html {
    font-size: 0.785em;
  }

  .reveal .slides {
    /* max-width: 82%; */
    top: -78px !important;
  }
}

@media (max-width: 330px) {
  html {
    font-size: 0.685em;
  }

  .reveal .slides {
    /* max-width: 82%; */
    top: -48px !important;
  }
}

.reveal h1 {
  margin-left: -0.05em;
  margin-bottom: 5px;
}

.reveal h2 {
  margin-left: -0.032em;
}

.reveal h3 {
  margin-left: -0.026em;
}

.reveal h4 {
  margin-left: -0.02em;
}

.reveal p {
  margin: 10px 0 20px;
}

.reveal .slides {
  opacity: 1;
  display: flex;
  width: 100%;
  padding-left: 4%;
  padding-right: 4%;
  height: 75%;
  position: relative;
  top: -32px;
  pointer-events: initial;
  text-align: left;
  align-items: center;
  justify-content: center;
}
/* @media (max-width: 767px) {
  .reveal .slides {
    width: 94%;
  }
}
@media (min-width: 768px) {
  .reveal .slides {
    max-width: 82%;
    top: -32px;
  }
}
@media (min-width: 1024px) {
  .reveal .slides {
    max-width: 76%;
  }
}
@media (min-width: 1440px) {
  .reveal .slides {
    max-width: 72%;
  }
} */

.reveal .slides section {
  display: flex;
  flex-direction: column;
  padding: 8% 2%;
}

.reveal .slides section section {
  padding: 8% 2%;
}

.reveal .slides > section.stack {
  align-items: center;
  justify-content: center;
}

#mainNav {
  padding: 2%;
}
@media (max-width: 767px) {
  #mainNav {
    padding: 4% 6%;
  }
}

.rep {
  border: none;
  display: block;
  height: 66px !important;
  width: 66px !important;
  position: fixed;
  top: auto;
  right: auto;
  bottom: 28px;
  left: 24px;
  visibility: visible;
  z-index: 999;
  transition: none 0s ease 0s;
  background: none transparent;
  opacity: 1;
  pointer-events: auto;
  touch-action: auto;
  margin: 0 !important;
  cursor: pointer;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.22, 1, 0.2, 1);
}

#repChat {
}

svg {
  display: inline-block;
}

#playCon {
  border: none;
  display: block;
  position: fixed;
  top: auto;
  right: auto;
  bottom: 27px;
  left: 100px;
  visibility: visible;
  z-index: 100;
  background: none transparent;
  opacity: 1;
  pointer-events: auto;
  touch-action: auto;
  cursor: pointer;
  z-index: 10;
  outline: none;
  width: 52px;
  height: 52px;
  padding: 0;
  margin: 8px;
  transition: all 0.25s;
}

.reveal .controls {
  position: fixed !important;
}

/*********************************************
 * Override Type
 *********************************************/
.reveal h1 {
  font-family: "GT Super", serif !important;
  font-size: 4.5rem;
  line-height: 4.125rem;
  font-weight: 300;
  margin-bottom: 0.875rem;
}

@media (min-width: 768px) {
  .reveal h1 {
    font-size: 5.125rem;
    line-height: 4.875rem;
  }
}

.reveal p {
  margin: 2.5px 0 15px;
  line-height: 1.35;
  color: #000;
}

@media (min-width: 768px) {
  .reveal p {
    /* font-size: 88%; */
  }
}

.reveal small {
  font-size: 1.125rem;
  font-weight: 700;
  text-transform: uppercase;
}

.reveal ul,
.reveal ol {
  /* font-size: 95%; */
  line-height: 1.15;
  margin-top: 0;
}

.reveal li {
  margin-bottom: 6px;
}

.bold {
  font-weight: 700;
  margin-top: 24px !important;
  opacity: 0.925;
}

input {
  border-radius: 0.12em;
  border-width: 0.5px;
}

.btn {
  font-size: 1.5rem !important;
  display: inline-block;
  padding: 0.35em 1.2em;
  margin: 0.25em 0 0.3em;
  border-radius: 0.12em;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: 700;
  transition: all 0.2s;
  text-align: center;
  cursor: pointer !important;
}

@media (max-width: 767px) {
  .btn,
  input {
    width: 100%;
  }
}

@media all and (max-width: 30em) {
  button {
    display: block;
  }
}

.slideIcon {
  width: 3.875em;
  max-height: 4.125em;
  top: -6px;
  left: -2px;
  position: relative;
  display: flex;
}

@media (min-width: 768px) {
  .slideIcon {
    width: 4.125em;
    max-height: 4.25em;
    top: 0px;
  }
}

.slideIconLg {
  width: 5.875em;
  max-height: 3.125em;
  top: -6px;
  left: -2px;
  position: relative;
  display: flex;
}

@media (min-width: 768px) {
  .slideIconLg {
    width: 8.125em;
    max-height: 4.25em;
    top: 0px;
  }
}

@media (min-width: 768px) {
  .pullRight {
    order: 3;
  }
}

.textCenter {
  text-align: center;
  margin: 0 auto;
}

.flex {
  display: flex;
}

.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 768px) {
  .flexCenterLg {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.ghosted {
  opacity: 0 !important;
  cursor: default !important;
  transition: all 0.5s;
  pointer-events: none;
  touch-action: none;
}

.unghosted {
  opacity: 1 !important;
  transition: all 0.5s;
}

@media (max-width: 767px) {
  .ghostedMobile {
    opacity: 0 !important;
    cursor: default !important;
    transition: all 0.5s;
  }

  .unghostedMobile {
    opacity: 1 !important;
    transition: all 0.5s;
  }
}

.referLogo {
  max-width: 66%;
  width: 350px;
  float: right;
  margin-bottom: 15px;
}

.referLogoWide {
  max-width: 74%;
  width: 420px;
  float: right;
  margin-bottom: 15px;
}

.right {
  float: right;
}

.left {
  float: left;
}

.openChatBtn {
  margin-top: 15px;
}

.inline {
  display: inline;
}

.inlineBlock {
  display: inline-block;
}

.captureEmail {
  padding: 7px;
  margin: 0 0 8px;
  font-family: "Montserrat";
  max-width: 100%;
}

@media (min-width: 768px) {
  .captureEmail {
    margin: 0 15px 8px 0;
  }
}

.marginRight15 {
  margin-right: 15px;
}

.fullWidth {
  width: 100%;
  max-width: 100%;
  display: contents;
}

.productImageCon {
  padding: 0 !important;
  max-height: 525px;
}

.reveal .productImage {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  margin: 0;
}

.productContain {
  max-width: 525px !important;
  max-height: 525px;
  background-color: #000000;
  overflow: hidden;
}

@media (min-width: 768px) {
  .productContain {
    flex: 1;
  }
}

.reveal .productDesc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px 15px 15px 50px;
}

@media (max-width: 767px) {
  .reveal .productDesc {
    padding: 15px 5px 15px 20px;
  }
}

.citation {
  color: #000000;
  text-align: right;
}

.quoteBar {
  background-color: white;
  height: 15px;
  width: 100%;
}

.white,
.white li {
  color: #ffffff !important;
  fill: #ffffff !important;
}

.headline {
  font-family: "GT Super" !important;
}

.iconArrowDown {
  width: 100%;
  min-width: 45px;
  max-width: 65px;
}

.hide {
  display: none !important;
}

@media (max-width: 767px) {
  .reveal .hideSmall {
    display: none;
  }
}
@media (min-width: 768px) {
  .reveal .hideLarge {
    display: none;
  }
}

.reveal .ulChecks {
  margin: 0;
}

.liChecks {
  content: "";
  list-style: none;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/use-captiv.appspot.com/o/iconCheck.svg?alt=media&token=758a74b4-58c7-4669-bdec-f7c152f638a0");
  background-size: contain;
  background-repeat: no-repeat;
  padding-left: 1.625em;
  margin-left: 0.25em;
  background-size: 21px 21px;
}

.iconArrowCurl {
  align-items: flex-end;
  justify-content: flex-end;
  display: flex;
  padding-bottom: 15px;
}

.iconArrowCurl svg {
  width: 100%;
  max-width: 45px;
}

@media (max-width: 767px) {
  .iconArrowCurl {
    padding-bottom: 60px;
  }
}

/* Slides icons */

.arrow {
  width: 10rem;
  position: relative;
  top: 12px;
  left: 12px;
}

.iconHome {
  width: 1.25em;
  position: relative;
  top: 21px;
  display: inline-block;
}

.underline {
  position: relative;
}

.underline:after {
  content: url("https://firebasestorage.googleapis.com/v0/b/use-captiv.appspot.com/o/3-icon-underline.svg?alt=media&token=8510b38a-cbb2-453f-91ab-feedb8fd175c");
  position: absolute;
  bottom: -0.25rem;
  left: -0.5rem;
  right: -0.5rem;
  height: 0.75rem;
  z-index: 0;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 767px) {
  .arrow {
    float: right;
    width: 50%;
    max-width: 150px;
    left: 0;
    top: 0;
  }
}

@keyframes pulse {
  0% {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.2, 1.2, 1.2);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

.pulse {
  animation: pulse 2s infinite;
  -webkit-animation: pulse 2s infinite;
  animation-timing-function: ease-in-out;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes blinkLong {
  0% {
    opacity: 0;
  }
  42% {
    opacity: 0;
  }
  45% {
    opacity: 1;
  }
  55% {
    opacity: 1;
  }
  57% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.blink {
  animation: blink 5s infinite;
  -webkit-animation: blink 5s infinite;
  animation-timing-function: ease-in-out;
}

.blinkLong {
  animation: blinkLong 30s infinite;
  -webkit-animation: blinkLong 30s infinite;
  animation-timing-function: ease-in-out;
}

.black {
  color: #000000;
  fill: #000000;
}

.hasMessage {
  content: "1";
  height: 0.85em;
  width: 0.85em;
  color: white;
  position: fixed;
  position: fixed;
  top: auto;
  right: auto;
  bottom: 26px;
  left: 22px;
  visibility: visible;
  transition: all 0.5s ease;
  background: none transparent;
  opacity: 1;
  pointer-events: auto;
  touch-action: auto;
  margin: 0 !important;
  cursor: pointer;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.22, 1, 0.2, 1);
  z-index: 9999999999;
}
